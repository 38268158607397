import { graphql, useStaticQuery } from 'gatsby';

export const useContentData = () => {
  const { allDataYaml } = useStaticQuery(
    graphql`
    query ContentQuery {
      allDataYaml {
        edges {
          node {
            work {
              title
              description
            }
            education {
              title
              description
            }
            stacks {
              title
              description
            }
          }
        }
      }
    }
        
    `
  );
  return allDataYaml.edges.length > 0 ? allDataYaml.edges[0].node : {};
};
