import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import Avatar from "@material-ui/core/Avatar"
import Typography from "@material-ui/core/Typography"
import RoomIcon from '@material-ui/icons/Room';
import DateRangeIcon from '@material-ui/icons/DateRange';
import BusinessIcon from '@material-ui/icons/Business';
import LaunchIcon from '@material-ui/icons/Launch';
import { Grid, Link } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  media: {},
  gridRow: {
    paddingLeft: theme.spacing(2),
  },
  avatar: {},
}))

const WorkCard = item => {
  const classes = useStyles()

  const imgUrl = item.img ? require("../images/" + item.img) : ""

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar
            variant="rounded"
            className={classes.avatar}
            alt={item.company}
            src={imgUrl}
          />
        }
        title={item.title}
      />
      <CardContent>
        <Grid container>
          <Grid><BusinessIcon /><Typography variant="srOnly">Company</Typography></Grid><Grid><Typography className={classes.gridRow}>{item.company}</Typography></Grid>
        </Grid>
        <Grid container>
          <Grid><DateRangeIcon /><Typography variant="srOnly">Time in Position</Typography></Grid><Grid><Typography className={classes.gridRow}>{item.endDate ? item.endDate : `Present`} — {item.startDate}</Typography></Grid>
        </Grid>
        <Grid container>
          <Grid><RoomIcon /><Typography variant="srOnly">Location</Typography></Grid><Grid><Typography className={classes.gridRow}>{item.location}</Typography></Grid>
        </Grid>
        <Grid container>
          <Grid><LaunchIcon /><Typography variant="srOnly">More Info</Typography></Grid><Grid><Typography className={classes.gridRow}><Link href={item.url} target="_blank">Marketing</Link></Typography></Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default WorkCard
