import React from "react"
import Grid from "@material-ui/core/Grid"

import WorkCard from "./workCard";

const WorkItem = entry => {

  return (
    <Grid item xs={12} sm={6} key={entry.id}>
      <WorkCard {...entry} />
    </Grid>
  )
}

export default WorkItem;
