import React from "react"
import Grid from "@material-ui/core/Grid"
import StackCard from "./stackCard";

const StackItem = entry => {
  return (
    <Grid item xs={6} sm={4} key={entry.id}>
      <StackCard {...entry} />
    </Grid>
  )
}

export default StackItem;
