import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import Avatar from "@material-ui/core/Avatar"
import { Grid, Tooltip } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  media: {},
  gridRow: {
    paddingLeft: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
  },
}))

const StackCard = item => {
  const classes = useStyles()

  const date = new Date(item.date);

  const header = () => {
    const img = require("../images/stacks/production.png");
    return item.production ?
      (<CardHeader
        avatar={
          <Avatar
            variant="rounded"
            src={img}
          />
        }
        title={date.getFullYear()}
      />) : (
        <CardHeader
          title={date.getFullYear()}
        />)
  }

  return (
    <Card className={classes.root}>
      {header()}
      <CardContent>
        <Grid container>
          {item.items && item.items.map((tech, index) => {
            let img = "";
            try {
              img = require("../images/stacks/" + tech + ".png");
            }
            catch {}

            return (
            <Tooltip title={tech} arrow id={tech + "-" + index}>
              <Avatar className={classes.avatar} alt={tech} src={img} />
            </Tooltip>
          )})}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default StackCard
