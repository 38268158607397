import React from "react"
import Grid from "@material-ui/core/Grid"

import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

import { useStackData } from "../hooks/useStackData";
import { useContentData } from "../hooks/useContentData";
import { Typography, Divider } from "@material-ui/core";

import StackItem from "./stackItem";

const useStyles = makeStyles(theme => ({
  hide: {
    display: "none",
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  section: {
    marginBottom: theme.spacing(2),
  }
}))

const Stack = ({ location, hash, title }) => {
  const classes = useStyles()
  const hide = location.hash && location.hash !== hash

  const stacks = useStackData();
  const content = useContentData();

  return (
    <div className={clsx(classes.section, { [classes.hide]: hide })}>
      <Typography variant="subtitle1" className={classes.paper}>
        {title}
      </Typography>
      <Divider className={classes.divider} />
      <Typography variant="body2" className={classes.content}>{content.stacks.description}</Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        {stacks.sort((a,b) => new Date(b.date) - new Date(a.date)).map((stack, index) => (
          <StackItem {...stack} id={`stack-` + index}/>
        ))}
      </Grid>
    </div>
  )
}

export default Stack
