import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
      fontFamily: [
        "IBM Plex Sans", 
        "Helvetica",
        "Arial",
        "sans-serif",
      ].join(','),
    },
    palette: {
      primary: {
        main: "#26428b",
      },
    },
    overrides: {
      MuiAvatar: {
        img: {
          // handle correctly non-square images
          objectFit: 'cover',
          height: '100%',
          marginTop: '30px',
        },
      },
    },
  });

export default theme;