import { graphql, useStaticQuery } from 'gatsby';

export const useEducationData = () => {
  const { allEducationYaml } = useStaticQuery(
    graphql`
    query EducationQuery {
        allEducationYaml (sort: {fields: year, order: DESC}) {
          nodes {
            year
            degree
            img
            institution
            location
            url
            id
          }
        }
      }
    `
  );
  return allEducationYaml.nodes;
};
