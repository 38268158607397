import React from "react"
import Grid from "@material-ui/core/Grid"

import EducationCard from "./educationCard"

const EducationItem = item => {

  return (
    <>
    <Grid item xs={12} sm={6} key={item.id}>
      <EducationCard {...item} />
    </Grid>
    </>
  )
}

export default EducationItem
