import { graphql, useStaticQuery } from 'gatsby';

export const useWorkData = () => {
  const { allWorkYaml } = useStaticQuery(
    graphql`
    query WorkQuery {
      allWorkYaml(sort: {fields: endDate, order: DESC}) {
        nodes {
          company
          endDate(formatString: "YYYY-MM")
          startDate(formatString: "YYYY-MM")
          title
          img
          url
          urlLinkText
          location
          id
        }
      }
    }
    `
  );
  return allWorkYaml.nodes;
};
