import Avatar from "@material-ui/core/Avatar"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { Typography, CardContent, Link, Grid } from "@material-ui/core"
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles(theme => ({
  media: {},
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  gridRow: {
    paddingLeft: theme.spacing(2),
  },
  avatar: {},
}))

const EducationCard = item => {
  const classes = useStyles()

  const imgUrl = item.img ? require("../images/" + item.img) : ""

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar
            variant="rounded"
            className={classes.avatar}
            alt={item.institution}
            src={imgUrl}
          />
        }
        title={item.institution + " | " + item.location}
        subheader={item.year + " " + item.degree}
      />
      <CardContent>
      <Grid container>
          <Grid><LaunchIcon /><Typography variant="srOnly">More Info Icon</Typography></Grid><Grid><Typography className={classes.gridRow}><Link href={item.url} target="_blank">More info</Link></Typography></Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default EducationCard
