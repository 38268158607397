import React from "react"
import Grid from "@material-ui/core/Grid"

import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

import EducationItem from "./educationItem"
import { useEducationData } from "../hooks/useEducationData"
import { useContentData } from "../hooks/useContentData"
import { Typography, Divider } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  hide: {
    display: "none",
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  section: {
    marginBottom: theme.spacing(2),
  }
}))

const Education = ({ location, hash, title }) => {
  const classes = useStyles()
  const hide = location.hash && location.hash !== hash

  const educationNodes = useEducationData()
  const content = useContentData()

  return (
    <div className={clsx(classes.section, { [classes.hide]: hide })}>
      <Typography variant="subtitle1" className={classes.paper}>
        {title}
      </Typography>
      <Divider className={classes.divider} />
      <Typography variant="body2" className={classes.content}>{content.education.description}</Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        {educationNodes.map(node => (
          <EducationItem {...node} key={node.id} />
        ))}
      </Grid>
    </div>
  )
}

export default Education
