import { graphql, useStaticQuery } from 'gatsby';

export const useStackData = () => {
  const { allStacksYaml } = useStaticQuery(
    graphql`
    query StackQuery {
      allStacksYaml {
        nodes {
          stacks {
            date(formatString: "YYYY-MM")
            items
            production
          }
        }
      }
    }
    `
  );
  return allStacksYaml.nodes.length > 0 ? allStacksYaml.nodes[0].stacks : [];
};
