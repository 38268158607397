import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Education from "../components/education"
import Work from "../components/work"
import { ThemeProvider } from "@material-ui/core"

import theme from "../utils/theme"
import { useContentData } from "../hooks/useContentData";
import Stack from "../components/stacks"

const IndexPage = ({ location }) => {

  const content = useContentData();
  
  return (
    <ThemeProvider theme={theme}>
      <SEO title="Chris Weber" />
      <Layout>
        <Education location={location} hash="#education" title={content.education.title} />
        <Work location={location} hash="#work" title={content.work.title} />
        <Stack location={location} hash="#stacks" title={content.stacks.title} />
      </Layout>
    </ThemeProvider>
  )
}

export default IndexPage
